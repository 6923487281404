.faq {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  height: 70%;
  padding: 0px 15px 60px;
  margin: 0 auto;
  max-width: 780px;
  background: #f4faff;
  overflow: hidden;
  border-radius: 20px;

  &_header {
    padding: 25px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_title {
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      position: relative;
    }
  }

  &_content {
    padding: 4px 5px 120px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &_item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: #fff;
      box-shadow: 1px 0px 6px 0px #00000040;
      padding: 11px 13px;
      border-radius: 8px;
      font-weight: 600;
      font-size: 0.8rem;
      cursor: pointer;

      & > :first-child {
        flex-shrink: 0;
        margin-right: 6px;
      }

      span {
        flex-shrink: 1;
        width: 100%;
      }

      &:hover {
        background: #dbefff;
      }
    }
  }

  &_footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    opacity: 1;
    background: linear-gradient(360deg, #f4faff 0%, rgba(244, 250, 255, 0) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
}

.authorization-form{
  //margin: 0 auto;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-content: center;
  //height: 100%;
  //width: 80vw;
  //max-width: 600px;
  //text-align: center;
  flex: 1 1 auto;
  position: relative;
  display: flex;
  width: 100%;
  min-height: 168px;
  text-align: center;
  justify-content: center;
  overflow-y: auto;

}
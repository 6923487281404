.commonBackdrop {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.33, 0, 0, 1);
  backdrop-filter: blur(4px);
}

.commonBackdrop.showBackdrop {
  transition: opacity 0.2s cubic-bezier(0.33, 0, 0, 1);
  opacity: 1;
}
